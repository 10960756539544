// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-groups-other-js": () => import("./../../../src/pages/groups/other.js" /* webpackChunkName: "component---src-pages-groups-other-js" */),
  "component---src-templates-group-mdx-template-js": () => import("./../../../src/templates/groupMdxTemplate.js" /* webpackChunkName: "component---src-templates-group-mdx-template-js" */),
  "component---src-templates-mdx-template-js": () => import("./../../../src/templates/mdxTemplate.js" /* webpackChunkName: "component---src-templates-mdx-template-js" */)
}

